.masonry {
  width: 400px !important;
  border-radius: 20px !important;
}

@media (max-width: 600px) {
  .masonry {
    width: 90vw !important;
  }
}

@media (min-width: 1000px) {
  .masonry {
    width: 50vw !important;
  }
}
@media (min-width: 200px) {
  .gridLayout {
    padding: 10px !important;
  }
}

.mintAddyHeader {
  font-size: 16px !important;
  margin-bottom: 20px !important;
  margin: 10px !important;
  color: #252525cd !important;
  font-weight: 500 !important;
}
.mintAddy {
  color: #252525b6 !important;
  font-size: 16px !important;
  margin-bottom: 20px !important;
  margin: 10px !important;
  word-break: break-all;
  
}
.mintCard {
  /* max-width: 345px; */
  /* border-radius: 10px!important; */
  border: 3px solid #9eceb8;
  border-radius: 13px !important;
}
.mintCard p{
  
    color: rgba(138, 138, 138, 0.479);
}
.mintCard div{
    color: black;
  
}
/* .mintCard:hover{
} */
.mintBtn {
  font-size: 14px !important;
  width: 100%;
  height: 45px;
  border-radius: 10px !important;
  background-color: #bcffe06f !important;
  color: #2525257d !important;
  font-size: 0.7rem !important;
font-weight: 700 !important;}
.mintBtn:hover {
  background-color: rgb(19, 20, 19) !important;
  color: whitesmoke !important;
}
.cardContent {
  padding: 0px !important;
}
.gridLayout {
  padding: 30px !important;
  margin: 0px !important;
  margin-left: -16px !important;
}
.headerText {
  display: flex;
  justify-content: center;
  /* width: 95%; */
  /* margin: auto; */
  /* border-radius: 10px; */
  /* border: 1px solid black; */
  padding: 12px;
  /* background: rgb(231, 231, 231)!important; */
  background: whitesmoke;
}
.headerBtn {
  /* border-radius: 10px!important; */
  font-size: 2em;
  /* padding: 5px !important; */
  /* background-color: white!important; */
  color: black !important;
}
.active {
  /* background-color: rgb(255, 255, 255) !important; */
  border-bottom: 2px solid black !important;
  color: black !important;
}
.MuiButtonBase-root:focus {
    outline: none;
}
.wallet-adapter-dropdown-list-item{
    margin-left: 0;
}
.mintCard{
  text-align: center;
  font-family: 'Roboto', sans-serif;;
  width: 240px;
  height: 346px;
  background-color: #9eceb8 !important;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));
}
img{
  height: 220px;
}
.ggs{
  text-decoration: none !important;
  color: #252525a3 !important;
  font-weight: 500 !important;
}